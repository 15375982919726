import React from "react";

export default function Hint({ text }: { text: string }) {
  return (
    <span
      className="text-muted text-sm markdown-content prose"
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
}
