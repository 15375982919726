import React from "react";

export default function FormActions({
  onCancel,
  saveDisabled,
}: {
  onCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
  saveDisabled?: boolean;
}) {
  return (
    <div className="tixxt__form--actions">
      <button onClick={onCancel} className="btn btn-light">
        {I18n.t("js.cancel")}
      </button>
      <button className="btn btn-primary" disabled={saveDisabled}>
        {I18n.t("js.save")}
      </button>
    </div>
  );
}
