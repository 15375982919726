import activitystreamsRoutes from "components/activitystreams/routes";
import appointmentsRoutes from "components/appointments/routes";
import messagesRoutes from "components/messages/routes";
import filesRoutes from "components/files/routes";
import tasksRoutes from "components/tasks/routes";
import globalSearchRoutes from "components/globalSearch/routes";
import administrationRoutes from "components/administration/routes";
import directoryRoutes from "components/directory/routes";
import membershipRoutes from "components/memberships/routes";
import groupRoutes from "components/groups/routes";
import onboardingRoutes from "components/onboarding/routes";
import invitesRoutes from "components/invites/routes";
import mapRoutes from "components/memberMap/routes";
import chatRoutes from "components/chat/routes";
import channelRoutes from "components/channels/routes";
import RedirectToStartpage from "components/tixxt/RedirectToStartpage";
import React from "react";

export default [
  ...activitystreamsRoutes,
  ...appointmentsRoutes,
  ...messagesRoutes,
  ...filesRoutes,
  ...tasksRoutes,
  ...globalSearchRoutes,
  ...administrationRoutes,
  ...directoryRoutes,
  ...membershipRoutes,
  ...groupRoutes,
  ...onboardingRoutes,
  ...invitesRoutes,
  ...mapRoutes,
  ...chatRoutes,
  ...channelRoutes,
  { path: "/", element: <RedirectToStartpage /> },
];
