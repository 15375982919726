import React from "react";
import { Controller, Control } from "react-hook-form";
import MarkDownEditor from "../../markdownEditor";
import Label from "components/shared/form/Label";

type TextFieldArgs = {
  name: string;
  control: Control<any>;
  label?: string;
  required?: boolean;
};
export default function TextEditor({
  name,
  control,
  label,
  required = false,
}: TextFieldArgs) {
  return (
    <div className="control-group">
      {label && <Label label={label} required={required} />}
      <div className="controls">
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            return (
              <MarkDownEditor
                {...field}
                content={field.value}
                placeholder={I18n.t(
                  "js.calendars.appointment.description.placeholder",
                )}
              />
            );
          }}
        />
      </div>
    </div>
  );
}
