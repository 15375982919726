import React, { useState } from "react";
import { useQuery } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import ToolBar from "components/directory/channels/ToolBar";
import Pagination from "components/shared/Pagination";
import { ceil, isEmpty, map, replace } from "lodash";
import { stringify } from "query-string";

type Channel = {
  id: string;
  slug: string;
  title: string;
  description: string;
  status: string;
  image: { _id: string };
};

type ChannelData = {
  pagination: {
    total: number;
    page: number;
    per_page: number;
  };
  entries: Channel[];
};

export default function ChannelListView() {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedLetter, setSelectedLetter] = useState("");
  const [filter, setFilter] = useState({});

  const { data, isLoading, refetch } = useQuery<ChannelData, Error>(
    `/directory/channels.json?${stringify({
      page: currentPage,
      letter: selectedLetter,
      ...filter,
    })}`,
  );

  function onPaginate(page: number) {
    setCurrentPage(page);
  }

  function onSelectLetter(letter: string) {
    // remove all non-alphabetic characters
    const cleanLetter = replace(letter, /[^a-zA-Z]/g, "");
    setSelectedLetter(cleanLetter);
    setCurrentPage(1);
    setFilter({});
    refetch();
  }

  function onSearchQuery(filter: { q: string; only_subscriptions: boolean }) {
    setSelectedLetter("");
    setFilter(filter);
    refetch();
  }

  function subscribeChannel(channelId: string) {
    console.log("SUBSCRIBE", channelId);
  }

  return (
    <div className="flex flex-col gap-y-4">
      <ToolBar
        onSelectLetter={onSelectLetter}
        onSearch={onSearchQuery}
        selectedLetter={selectedLetter}
      />
      <div className="flex justify-end">
        <Pagination
          page={currentPage}
          totalPages={
            data && ceil(data?.pagination.total / data?.pagination.per_page)
          }
          onPaginate={onPaginate}
          loading={isLoading}
        />
      </div>
      <div className="flex flex-col divide-y divide-neutral media-list">
        {data && !isEmpty(data?.entries)
          ? map(data.entries, (channel) => (
              <div key={channel.id} className="flex gap-3 py-2 items-center">
                <div className="flex justify-center items-center w-16 h-16">
                  {channel.image._id ? (
                    <img
                      className="h-16 w-16"
                      src={`/api/storage/images/${channel.image._id}/get`}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="max-h-16 max-w-16 text-gray-400"
                      icon={regular("bullhorn")}
                      size="2xl"
                    />
                  )}
                </div>
                <div className="media-body grow">
                  <a
                    className="media-heading"
                    href={`/channels/${channel.slug}`}
                  >
                    <div className="flex gap-1 items-center">
                      <div className="font-medium">{channel.title}</div>
                    </div>
                  </a>
                  <p className="text-muted text-sm">
                    {I18n.t("js.directory.channel_row.subscriptions_count", {
                      count: 0, // TODO: #3072
                    })}
                  </p>
                  <p className="max-w-4xl mt-1 text-sm whitespace-pre-line">
                    {channel.description}
                  </p>
                </div>
                <div className="">
                  <div className="btn-group">
                    {/* TODO: #3072 */}
                    <button
                      className="btn btn-light"
                      onClick={(e) => {
                        e.preventDefault();
                        subscribeChannel(channel.id);
                      }}
                    >
                      {/* TODO: I18n.t("js.directory.channel_row.unsubscribe.title")*/}
                      {I18n.t("js.directory.channel_row.subscribe.title")}
                    </button>
                  </div>
                </div>
              </div>
            ))
          : I18n.t("js.directory.empty_collection")}
      </div>
      <div className="flex justify-end">
        <Pagination
          page={currentPage}
          totalPages={
            data && ceil(data?.pagination.total / data?.pagination.per_page)
          }
          onPaginate={onPaginate}
          loading={isLoading}
        />
      </div>
    </div>
  );
}
