import React from "react";
import TixxtModuleSwitcher from "components/layout/TixxtModuleSwitcher";
import {
  ContextNavigation,
  UserbarLink,
  UserbarSection,
} from "components/layout/Userbar";

export default function ChannelsUserbar() {
  return (
    <>
      <TixxtModuleSwitcher activeModule="channels" />
      <ContextNavigation>
        <UserbarSection title={""}>
          <UserbarLink to={`/channels`}>Channels</UserbarLink>
        </UserbarSection>
      </ContextNavigation>
    </>
  );
}
