import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import React, { useState } from "react";
import classNames from "classnames";
import Glossary from "components/shared/Glossary";

type ToolBarArgs = {
  onSelectLetter: (letter: string) => void;
  onSearch: (props: { q: string; only_subscriptions: boolean }) => void;
  selectedLetter: string;
};

export default function ToolBar({
  onSelectLetter,
  onSearch,
  selectedLetter,
}: ToolBarArgs) {
  const [showFilter, setShowFilter] = useState(true);
  const [filterSubscriptions, setFilterSubscriptions] = useState(false);
  const [queryString, setQueryString] = useState("");

  function onSubmit(e) {
    e.preventDefault();
    onSearch({
      q: queryString,
      only_subscriptions: filterSubscriptions,
    });
  }

  return (
    <div className="border-box mt-6 p-3">
      <div className="flex flex-col gap-1 md:gap-0 md:flex-row md:divide-x md:divide-neutral">
        <div className="grow input-group md:mr-2 w-auto">
          <input
            name="q"
            type="text"
            placeholder={I18n.t("js.directory.search.in", {
              field: I18n.t("js.directory.channel_tab"),
            })}
            onChange={(e) => setQueryString(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && onSubmit(e)}
          />
          <button className="btn btn-primary" onClick={onSubmit}>
            <FontAwesomeIcon icon={regular("magnifying-glass")} />
          </button>
        </div>
        <div className="flex flex-wrap gap-1 justify-end sm:flex-nowrap sm:divide-x sm:divide-neutral sm:gap-0">
          <div>
            <button
              className={classNames("btn btn-light md:ml-2", {
                active: showFilter,
              })}
              onClick={(e) => {
                e.preventDefault();
                setShowFilter(!showFilter);
              }}
            >
              <FontAwesomeIcon icon={regular("filter")} />
            </button>
          </div>
          <div className="flex-none sm:ml-2">
            <a
              className="btn btn-primary sm:ml-2"
              href="/channels/new"
              title={I18n.t("js.directory.headline_tools.create_channel")}
            >
              <div className="flex gap-1 items-center">
                <FontAwesomeIcon icon={regular("circle-plus")} />
                <div className="span text-sm">
                  {I18n.t("js.directory.headline_tools.create_channel")}
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      {showFilter && (
        <div className="search-filter-bar">
          <div className="flex flex-col space-y-4">
            <Glossary
              onSelect={onSelectLetter}
              selectedLetter={selectedLetter}
            />
            <div className="flex flex-row gap-2 mt-4">
              <div className="search-subscribed">
                {/* TODO: #3072 */}
                <label className="checkbox">
                  <input
                    name="only_subscriptions"
                    type="checkbox"
                    checked={filterSubscriptions}
                    onChange={() =>
                      setFilterSubscriptions(!filterSubscriptions)
                    }
                  />
                  {I18n.t("js.directory.search.channel_subscriptions")}
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
