import MarkdownEditor from "./markdownEditor";
import TranslationControls from "./TranslationControls";
import Exporter, {
  AppItemsExporter,
  ConsumersExporter,
  MembershipsExporter,
} from "./SimpleExporter";
import wrapProvider from "../../helpers/components/wrapProvider";
import store from "../../store";
import RecurringAppointmentDecisionModal from "./RecurringAppointmentDecisionModal";

export default {
  "shared/MarkdownEditor": MarkdownEditor,
  "shared/Exporter": Exporter,
  "shared/AppItemsExporter": AppItemsExporter,
  "shared/MembershipsExporter": MembershipsExporter,
  "shared/ConsumersExporter": ConsumersExporter,
  "shared/TranslationControls": wrapProvider(store)(TranslationControls),
  "shared/RecurringAppointmentDecisionModal": RecurringAppointmentDecisionModal,
};
