import { useQuery } from "react-query";
import { fetchApi } from "../helpers/reactQueryApi.js";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { currentMemberSelector } from "selectors/environment";
import { sum, values } from "lodash";

/**
 * Features
 */
const unreadsQueryOptions = {
  refetchInterval: 60000,
};

type FeaturesUnreadsPayload = {
  Activitystreams: number;
  Messages: number;
  Tasks: number;
  Files: number;
  Calendars: number;
  GroupInvites: number;
  Invites: number;
  Notifications: number;
  GroupJoinRequests: { group_slug: string; open_join_requests: number }[];
};

export type AppUnreadsPayload = {
  total: number;
  activitystreams: number;
  appointments: number;
  messages: number;
};

const convertPayload = (payload: FeaturesUnreadsPayload): AppUnreadsPayload => {
  const features = {
    activitystreams: payload.Activitystreams || 0,
    appointments: payload.Calendars || 0,
    messages: payload.Messages || 0,
  };

  return {
    total: sum(values(features)),
    ...features,
  };
};

/*
 * Unread counts by features
 *
 * Delayed by 250ms to prioritize other requests
 * Skipped if no member is signed in
 */
export function useFeaturesUnreads() {
  const currentMember = Preload.current_member;
  const [delayedEnabled, setDelayedEnabled] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setDelayedEnabled(true), 250);
    return () => clearTimeout(timeout);
  }, []);

  return useQuery<FeaturesUnreadsPayload>(
    ["unreads", "features"],
    () => fetchApi("/unreads/features"),
    {
      ...unreadsQueryOptions,
      enabled: currentMember !== null && delayedEnabled,
      onSuccess: (data) => {
        if (window.isApp) {
          const unreads: AppUnreadsPayload = convertPayload(data);
          window.bridge?.postMessage({ unreads });
        }
      },
    },
  );
}

/**
 * Unread counts by activitystreams
 *
 * Delayed by 500ms to prioritize other requests
 * Skipped if no member is signed in
 */
type ActivitiesUnreadsPayload = {
  activitystream_slug: string;
  unreads: number;
  sum: number;
  notifications: number;
  invites: number;
  group_slug: string | null;
}[];

export function useActivitiesUnreads() {
  const currentMember = useSelector(currentMemberSelector);
  const [delayedEnabled, setDelayedEnabled] = useState(false);

  useEffect(() => {
    setTimeout(() => setDelayedEnabled(true), 500);
  }, []);

  return useQuery<ActivitiesUnreadsPayload, Error>(
    ["unreads", "activities"],
    () => fetchApi("/unreads/activities"),
    {
      ...unreadsQueryOptions,
      enabled: currentMember !== null && delayedEnabled,
      onSuccess: (data) => {
        Tixxt.Activitystreams.Activitystream.all().processUnreadCounts(data);
      },
    },
  );
}

/**
 * Folders
 */
type FoldersUnreadsPayload = {
  group_slug: string | null;
  pending_versions: number;
  reviewed_files: number;
  sum: number;
}[];

export function useFoldersUnreads(options = {}) {
  return useQuery<FoldersUnreadsPayload>(
    ["unreads", "folders"],
    () => fetchApi(`/unreads/folders`),
    { ...unreadsQueryOptions, ...options },
  );
}
