import React from "react";
import { UseFormRegister } from "react-hook-form";
import Label from "components/shared/form/Label";

type InputArgs = {
  name: string;
  register: UseFormRegister<any>;
  label?: string;
  required?: string | boolean;
  placeholder?: string;
  multiline?: boolean;
  maxLength?: number;
};

export default function TextInput({
  label,
  register,
  name,
  placeholder = "",
  required = false,
  multiline = false,
  maxLength,
}: InputArgs) {
  return (
    <div className="control-group">
      {label && <Label label={label} required={!!required} />}
      <div className="controls">
        {multiline ? (
          <textarea
            {...register(name, { required })}
            placeholder={placeholder}
            maxLength={maxLength}
          />
        ) : (
          <input
            type="text"
            {...register(name, { required })}
            placeholder={placeholder}
            maxLength={maxLength}
          />
        )}
      </div>
    </div>
  );
}
