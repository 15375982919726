import React from "react";
import { Control, Controller, UseFormRegister } from "react-hook-form";

import Label from "components/shared/form/Label";
import MemberSelect from "components/shared/MemberSelect";
import Checkbox from "components/shared/form/fields/Checkbox";
import classNames from "classnames";

export default function ParticipantsField({
  control,
  register,
}: {
  control: Control<any>;
  register: UseFormRegister<any>;
}) {
  return (
    <div className={"control-group remove-input-txt-border"}>
      <Label label={I18n.t("js.calendars.appointment.invites.label")} />
      <div
        className={classNames("controls", {
          "border-box p-3":
            !!Tixxt.currentNetwork.getConfig("consumers_enabled"),
        })}
      >
        <Controller
          name="participants"
          control={control}
          render={({ field }) => (
            <MemberSelect
              field={field}
              includeConsumers={
                !!Tixxt.currentNetwork.getConfig("consumers_enabled")
              }
              membersAndGroups
              includeMembershipRoles
              multi
            />
          )}
        />
        <span className="text-muted text-sm">
          {I18n.t("js.calendars.appointment.invites.disclaimer")}
        </span>
        {!!Tixxt.currentNetwork.getConfig("consumers_enabled") && (
          <div className="controls mt-2">
            <Checkbox
              name="invite_consumers"
              register={register}
              label={I18n.t(
                "js.calendars.appointment.invites.invite_consumers",
              )}
            />
            <span className="text-muted text-sm">
              {I18n.t("js.calendars.appointment.invites.tooltip")}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
