import { UseFormRegister } from "react-hook-form";
import React from "react";

export default function Checkbox({
  name,
  register,
  label,
}: {
  name: string;
  register: UseFormRegister<any>;
  label: string;
}) {
  return (
    <label className="checkbox gap-2 items-center">
      <input type="checkbox" {...register(name)} />
      {label}
    </label>
  );
}
