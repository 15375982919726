import React, { lazy, Suspense } from "react";
import { RouteObject } from "helpers/tixxt-router";
import ChannelsPage from "./ChannelsPage";

const ChannelNewPage = lazy(() => import("components/channels/ChannelNewPage"));

const channelRoutes: RouteObject[] = [
  { path: "/channels", element: <ChannelsPage /> },
  {
    path: "/channels/new",
    element: (
      <Suspense fallback={"Loading..."}>
        <ChannelNewPage />
      </Suspense>
    ),
  },
];

export default channelRoutes;
