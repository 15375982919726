import React, { useState } from "react";
import { Control, Controller, useWatch } from "react-hook-form";
import Label from "components/shared/form/Label";
import ReactSelect from "react-select";
import { isEmpty, map } from "lodash";
import moment from "moment";
import classNames from "classnames";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import DateTimePicker from "components/shared/form/fields/DateTimePicker";
import PartialToggle from "components/appointments/form/PartialToggle";

const FREQUENCY_LOOKUP = {
  weekly: "week",
  monthly: "month",
  yearly: "year",
  daily: "day",
};

type RecurringSelectArgs = {
  name: string;
  label?: string;
  control: Control<any>;
  required?: boolean;
  startDate: string;
};
export default function RecurringSelect({
  name,
  label,
  control,
  required,
  startDate,
}: RecurringSelectArgs) {
  return (
    <div className="control-group">
      {label && <Label label={label} required={required} />}
      <div className="controls">
        <Controller
          name={name}
          control={control}
          render={({ field }) =>
            renderRecurringSelect({ field, startDate, control })
          }
        />
      </div>
    </div>
  );
}

function renderRecurringSelect({ field, startDate, control }) {
  const { recurrence_frequency, recurrence_end_time } = useWatch({
    control,
    name: "recurrence_pattern",
  });
  const [showEndPicker, setShowEndPicker] = useState(
    !isEmpty(recurrence_end_time),
  );

  const options = map(
    ["never", "daily", "weekly", "monthly", "yearly"],
    (pattern) => ({
      value: pattern,
      label: I18n.t(
        `js.calendars.appointment.recurrence-pattern.frequency.${pattern}`,
      ),
    }),
  );

  function handleChange(value: { [key: string]: string | null }) {
    if (value.recurrence_frequency) {
      if (value.recurrence_frequency !== recurrence_frequency) {
        field.onChange({
          ...field.value,
          recurrence_end_time: null,
          ...value,
        });
        setShowEndPicker(false);
        return;
      }
    }

    field.onChange({
      ...field.value,
      ...value,
    });
  }

  return (
    <div className="border-box flex flex-col p-3 gap-y-3">
      <div className="control-group">
        <label className="control-label">
          {I18n.t("js.calendars.appointment.recurrence_pattern.interval.label")}
        </label>
        <div className="controls remove-input-txt-border">
          <ReactSelect
            closeMenuOnSelect
            noOptionsMessage={() => I18n.t("js.plugins.select2.no_match")}
            value={{
              value: field.value.recurrence_frequency,
              label: I18n.t(
                `js.calendars.appointment.recurrence-pattern.frequency.${recurrence_frequency}`,
              ),
            }}
            options={options}
            onChange={(selectedOption) =>
              handleChange({ recurrence_frequency: selectedOption?.value })
            }
            className="form-select-container"
            classNamePrefix="form-select"
            isSearchable={false}
            unstyled
          />
        </div>
      </div>
      {recurrence_frequency !== "never" && (
        <PartialToggle
          onToggle={(showPartial) => {
            handleChange({
              recurrence_end_time: showPartial
                ? null
                : field.value.recurrence_end_time,
            });
          }}
          label={(showPartial) =>
            showPartial
              ? I18n.t("js.calendars.date_range.remove_end")
              : I18n.t("js.calendars.date_range.set_end.link")
          }
          iconClassName={(showPartial) =>
            classNames({ "text-danger": showPartial })
          }
          hideIcon={regular("xmark")}
          shouldShowPartial={showEndPicker}
        >
          <DateTimePicker
            label={I18n.t("js.calendars.date_range.end_date.label")}
            date={
              recurrence_end_time ||
              moment(startDate)
                .add(1, FREQUENCY_LOOKUP[recurrence_frequency])
                .format()
            }
            onChange={(value: string) =>
              handleChange({ recurrence_end_time: value })
            }
            interval={moment.duration(
              1,
              FREQUENCY_LOOKUP[recurrence_frequency],
            )}
          />
        </PartialToggle>
      )}
    </div>
  );
}
